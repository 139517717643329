import type { OrusSessionUser } from '@orus.eu/backend/src/services/session/session-types'
import { memo } from 'react'
import { useDisconnect } from '../../../../lib/hooks/use-disconnect'
import { GenericProblemMessage } from '../../../organisms/generic-problem-message'

export const InsufficientRightsProblemMessage = memo<{ user: OrusSessionUser }>(
  function InsufficientRightsProblemMessage({ user }) {
    const disconnect = useDisconnect()
    return (
      <GenericProblemMessage
        title="Page inaccessible avec ce compte"
        principalMessage="Les droits de ce compte sont insuffisants pour voir cette page."
        firstSubText={`Vous êtes actuellement connecté(e) en tant que ${'email' in user ? user.email : user.unverifiedEmail}.`}
        buttonText="Me déconnecter"
        onButtonClick={disconnect}
      />
    )
  },
)
